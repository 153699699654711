import { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Form, Button, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

const Contact = () => {
    const [successMessage, setSuccessMessage] = useState(false)

    const [values, setValues] = useState({
        txtName: "",
        txtEmail: "",
        txtConfirmEmail: "",
        txtContent: ""
    });

    const [formerrors, setFormErrors] = useState({});
    //this method handles the each form field changing and updates the relevant
    //state value for that input
    const handleChange = (event) => {
        setValues((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };

    //this method will check each field in your form. You define
    //the rules for each field
    const validate = () => {
        let errors = {};

        //name field
        if (!values.txtName) {
            errors.txtName = "Name is required";
        }
        //email field
        if (!values.txtEmail) {
            errors.txtEmail = "Email address is required";
        } else if (!/\S+@\S+\.\S+/.test(values.txtEmail)) {
            errors.txtEmail = "Email address is invalid";
        }
        //confirm email field
        if (!values.txtConfirmEmail) {
            errors.txtConfirmEmail = "Confirm email address is required";
        } else if (!/\S+@\S+\.\S+/.test(values.txtConfirmEmail)) {
            errors.txtConfirmEmail = "Confirm email address is invalid";
        } else if (values.txtEmail !== values.txtConfirmEmail) {
            errors.txtConfirmEmail = "Confirm email address did not match with email address";
        }
        //subject field
        if (!values.optSubject) {
            errors.optSubject = "Subject is required";
        }
        //content field
        if (!values.txtContent) {
            errors.txtContent = "Content is required";
        }
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };
    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        if (validate(values)) {
            SendContactEmail()
        }
    };

    const SendContactEmail = async () => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/home/contact`,
            data: {
                name: values.txtName,
                email: values.txtEmail,
                subject: values.optSubject,
                content: values.txtContent
            }
        }).then(dataContact => {
            if (dataContact.data === 'Sent') {
                setValues({
                    txtName: "",
                    txtEmail: "",
                    txtConfirmEmail: "",
                    txtContent: ""
                })

                setSuccessMessage(true)
            }
        })
    };

    return (
        <div className='empty-row'>
            <Helmet>
                <title>Contact SnowJapan</title>
            </Helmet>
            <div>
                <div className="sj-para-image mb-4"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Contact.svg" alt="Japan as 'Snow Country'" /></div>
                <div>
                    <h3>Contact SnowJapan</h3>
                    <div className="sj-para">
                        Please use the below form to contact SnowJapan.
                    </div>
                    <h5 className="sj-para">
                        Important:
                    </h5>
                    <div className="sj-para">
                        We believe our time is best spent improving our site's content for everyone's benefit.
                    </div>
                    <div className="sj-para">
                        That means we are not able to respond to specific questions about ski areas, hotels, ski schools or other services and businesses. We are also not able to make recommendations or help with travel arrangements. (However, some of the people introduced <Link to={window.$baseListingUrl}>in this section</Link> may be able to help).
                    </div>
                    <div className="sj-para">
                        Your email address will never be added to any list or shared. The only time we will use it is to reply to your enquiry.
                    </div>
                </div>
            </div>
            {successMessage &&
                <div className='empty-row sj-box sj-box-blue sj-round-full center-aligned'>
                    <h4>Thank you for contacting SnowJapan</h4>
                </div>
            }
            <div className="empty-row">
                <Form onSubmit={handleSubmit}>
                    <Row className="mb-3 sj-no-margin">
                        <Form.Group controlId="txtName" className="mt-2 mb-4">
                            <Form.Label><h5>Name</h5></Form.Label>
                            <Form.Control name="txtName" type="text" placeholder="Name" value={values.txtName} onChange={handleChange} />
                            {formerrors.txtName && (
                                <div className="text-danger">{formerrors.txtName}</div>
                            )}
                        </Form.Group>
                        <Form.Group controlId="txtEmail" className="mb-4">
                            <Form.Label><h5>Email</h5></Form.Label>
                            <Form.Control name="txtEmail" type="email" placeholder="Email" aria-describedby="igEmail" value={values.txtEmail} onChange={handleChange} />
                            {formerrors.txtEmail && (
                                <div className="text-danger">{formerrors.txtEmail}</div>
                            )}
                        </Form.Group>
                        <Form.Group controlId="txtConfirmEmail" className="mb-4">
                            <Form.Label><h5>Confirm email</h5></Form.Label>
                            <Form.Control name="txtConfirmEmail" type="email" placeholder="Confirm email" aria-describedby="igConfirmEmail" value={values.txtConfirmEmail} onChange={handleChange} />
                            {formerrors.txtConfirmEmail && (
                                <div className="text-danger">{formerrors.txtConfirmEmail}</div>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <Form.Label><h5>Subject</h5></Form.Label><br />
                            <Form.Check inline label="General enquiry" name="optSubject" id="optSubjectGeneral" type="radio" value="General enquiry" onChange={handleChange} />
                            <Form.Check inline label="Business and advertising enquiry" name="optSubject" id="optSubjectBusiness" type="radio" value="Business and advertising enquiry" onChange={handleChange} />
                            {formerrors.optSubject && (
                                <div className="text-danger">{formerrors.optSubject}</div>
                            )}
                        </Form.Group>
                        <Form.Group controlId="txtContent" className="mb-4">
                            <Form.Label><h5>Content</h5></Form.Label>
                            <Form.Control name="txtContent" as="textarea" rows={10} value={values.txtContent} onChange={handleChange} />
                            {formerrors.txtContent && (
                                <div className="text-danger">{formerrors.txtContent}</div>
                            )}
                        </Form.Group>
                        <div className='center-aligned'>
                            <Button type="submit" className='sj-large-font-button sj-row-100'>Send</Button>
                        </div>
                    </Row>
                </Form>
            </div>
        </div>
    )
}

export default Contact;